




import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { AuthStore } from "@/helpers/state";
import { clearSession } from "@/helpers/store";

@Component({
  name: "Signout"
})
export default class Signout extends Vue {
  @Action("signOut", AuthStore) signOut!: () => Promise<void>;

  async created() {
    document.title = this.$t("signout.title") as string;

    await this.signOut();
    clearSession();
  }
}
