import Vue from "vue";
import { Store } from "vuex";
import {
  Getters,
  Mutations,
  Actions,
  Module,
  Context
} from "vuex-smart-module";
import { checkResponse } from "@/helpers/store";
import { ApiVersion, AuthStoreType } from "@/types/state";
import { Connection, Config } from "@/types/config";
import { CheckResult, StatusType } from "@/types/checkResult";

import { AuthStore } from "./auth";

class UtilState {
  loading = false;
  error: CheckResult | null = null;
  config: Config | null = null;
  apiVersion: ApiVersion = {
    version: ""
  };
}

class UtilGetters extends Getters<UtilState> {}

class UtilMutations extends Mutations<UtilState> {
  setLoading(loading: boolean) {
    this.state.loading = loading;
  }

  setError(error: CheckResult) {
    this.state.error = error;
  }

  clearError() {
    this.state.error = null;
  }

  setConfig(config: Config) {
    this.state.config = config;
  }

  setApiVersion(apiVersion: ApiVersion) {
    this.state.apiVersion = apiVersion;
  }
}

class UtilActions extends Actions<
  UtilState,
  UtilGetters,
  UtilMutations,
  UtilActions
> {
  auth!: Context<typeof AuthStore>;

  $init(store: Store<AuthStoreType>): void {
    this.auth = AuthStore.context(store);
  }

  async getConfig() {
    try {
      this.mutations.setLoading(true);

      /* eslint-disable */
        const response = await Vue.axios.get(__webpack_public_path__ + "config.json");
        const config = response.data;
        
        let checkDebug = 0;
        if (Vue.$localStorage.hasKey("debug")) {
          checkDebug = Vue.$localStorage.get("debug");
        }
        if (checkDebug !== 1) {
          const regex = new RegExp("^https?:\/\/localhost");
          config.connections = [
            ...config.connections.filter((item: Connection) => !regex.test(item.url))
          ];
        }
  
        this.mutations.setConfig(config);
      } catch (error) {
        this.mutations.setError(error);
      } finally {
        this.mutations.setLoading(false);
      }
    }
    
    async ping() {
      try {
        this.mutations.setLoading(true);
  
        const response = await Vue.axios.post(
          this.auth.state.base.url,
          {
            cmd: "luaFunc",
            params: {
              script: "web.wbUtilLib",
              funcname: "wbPing"
            }
          },
          {
            headers: {
              Authorization: `{"token":["${this.auth.state.user.token}","${this.auth.state.user.tokenid}"]}`
            }
          }
        );
  
        const result: CheckResult = checkResponse(response);
        if (result.status !== StatusType.ok) {
          this.mutations.setError(result);
        }
      } catch (error) {
        this.mutations.setError(error);
      } finally {
        this.mutations.setLoading(false);
      }
    }

    async getApiVersion() {
      try {
        this.mutations.setLoading(true);
  
        const response = await Vue.axios.post(this.auth.state.base.url, {
          cmd: "getVers"
        });
  
        const result: CheckResult = checkResponse(response);
        if (result.status === StatusType.ok) {
          this.mutations.setApiVersion(response.data);
        } else {
          this.mutations.setError(result);
        }
      } catch (error) {
        this.mutations.setError(error);
      } finally {
        this.mutations.setLoading(false);
      }
    }
}

export const UtilStore = new Module({
  state: UtilState,
  getters: UtilGetters,
  mutations: UtilMutations,
  actions: UtilActions
})
