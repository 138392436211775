



























































































import { Component, Vue, Prop } from "vue-property-decorator";

interface MenuItem {
  title: string;
  route: string;
}

interface MenuGroup extends MenuItem {
  icon: string;
  children: Array<MenuItem>;
}

@Component({
  name: "GlobalMenu"
})
export default class GlobalMenu extends Vue {
  @Prop({ default: "" }) readonly luaVersion!: string;
  @Prop({ default: "" }) readonly apiVersion!: string;
  @Prop({ default: "" }) readonly webVersion!: string;
  @Prop({ default: "" }) readonly host!: string;
  @Prop({ default: "" }) readonly moduleTitle!: string;

  readonly menuInit: Array<MenuGroup> = [
    {
      icon: "el-icon-user",
      title: this.$t("home.buttons.subscribers") as string,
      route: "subscribers",
      children: [{ title: "Тест", route: "test" }]
    },
    {
      icon: "el-icon-data-analysis",
      title: this.$t("home.buttons.reports") as string,
      route: "reports",
      children: [{ title: "Тест", route: "test" }]
    },
    {
      icon: "el-icon-tickets",
      title: this.$t("home.buttons.reference") as string,
      route: "reference",
      children: [
        { title: "Список должностей", route: "jobList" },
        { title: "Дома", route: "homes" }
      ]
    },
    {
      icon: "el-icon-files",
      title: this.$t("menu.payments.title") as string,
      route: "payments",
      children: [
        {
          title: this.$t("menu.payments.children.bankpay") as string,
          route: "bankpay"
        },
        {
          title: this.$t("menu.payments.children.packsreceipts") as string,
          route: "packsreceipts"
        },
        {
          title: this.$t("menu.payments.children.relationship") as string,
          route: "relationship"
        },
        {
          title: this.$t("menu.payments.children.assignoperators") as string,
          route: "assignoperators"
        },
        {
          title: this.$t("menu.payments.children.controlpacks") as string,
          route: "controlpacks"
        },
        {
          title: this.$t("menu.payments.children.controlinetpays") as string,
          route: "controlinetpays"
        }
      ]
    }
  ];

  // иконка собранного меню (дефолтное значение)
  arrowIcon = "el-icon-d-arrow-right";

  menuButtonClick() {
    if (this.arrowIcon === "el-icon-d-arrow-right") {
      this.arrowIcon = "el-icon-d-arrow-left";
    } else this.arrowIcon = "el-icon-d-arrow-right";
  }

  handleSelect(route: string, keyPath: string): void {
    if (keyPath[0] !== "user-menu")
      window.location.href = `${this.host}${keyPath[0]}/${route}`;
    else if (route === "signout") this.onSignOut();
    else this.$message("Демо работы");
  }

  onSignOut(): void {
    this.$router.push("/signout");
  }
  logoClick(): void {
    window.location.href = this.host;
  }
}
