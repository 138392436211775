import Vue from "vue";
import { Store } from "vuex";
import {
  Getters,
  Mutations,
  Actions,
  Module,
  Context
} from "vuex-smart-module";
import router from "@/router";
import { checkResponse } from "@/helpers/store";
import { User, Base, AuthServer, UtilStoreType } from "@/types/state";
import {
  SignIn,
  AuthServers,
  AuthSections,
  AuthBases,
  GetSections
} from "@/types/auth";
import { CheckResult, StatusType } from "@/types/checkResult";
import { LSAuth, LSConnection } from "@/types/localStorage";

import { UtilStore } from "./util";

class AuthState {
  user: User = {
    login: null,
    language: null,
    token: null,
    tokenid: null,
    userid: null
  };

  base: Base = {
    url: "",
    label: ""
  };

  auth: AuthServer = {
    url: "",
    servers: [],
    sections: [],
    bases: []
  };
}

class AuthGetters extends Getters<AuthState> {
  get isUserSignin() {
    return (
      !!this.state.user.token &&
      !!this.state.user.tokenid &&
      !!this.state.user.userid
    );
  }

  get authServers() {
    return this.state.auth.servers;
  }

  get authSections() {
    return this.state.auth.sections;
  }

  get authBases() {
    return this.state.auth.bases;
  }
}

class AuthMutations extends Mutations<AuthState> {
  setUser(user: User) {
    this.state.user = user;
  }

  setBase(base: Base) {
    this.state.base = base;
  }

  setAuth(auth: AuthServer) {
    this.state.auth = auth;
  }

  setAuthUrl(url: string) {
    this.state.auth.url = url;
  }

  setAuthServers(authServers: Array<AuthServers>) {
    this.state.auth.servers = authServers;
  }

  setAuthSections(authSections: Array<AuthSections>) {
    this.state.auth.sections = authSections;
  }

  setAuthBases(authBases: Array<AuthBases>) {
    this.state.auth.bases = authBases;
  }
}

class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
> {
  util!: Context<typeof UtilStore>;

  $init(store: Store<UtilStoreType>): void {
    this.util = UtilStore.context(store);
  }

  async signIn({
    login,
    password,
    language,
    connection,
    url,
    label,
    server,
    section,
    base
  }: SignIn) {
    try {
      this.util.commit("setLoading", true);

      this.mutations.setBase({ url, label });

      if (language === "ua") language = "uk";
      const response = await Vue.axios.post(this.state.base.url, {
        cmd: "auth",
        params: {
          login: login,
          passwd: password,
          lang: language
        }
      });

      const result: CheckResult = checkResponse(response);
      if (result.status === StatusType.ok) {
        const { token, tokenid, userid } = response.data;

        this.mutations.setUser({
          login,
          language,
          token,
          tokenid,
          userid
        });

        const wbConnection: LSConnection = {
          login,
          language,
          connection,
          server,
          section,
          base,
          external: true
        };
        Vue.$localStorage.set("connection", wbConnection);

        const wbAuth: LSAuth = { url, label, token, tokenid, userid };
        Vue.$localStorage.set("auth", wbAuth);

        router.push("/");
      } else {
        this.util.commit("setError", result);
      }
    } catch (error) {
      this.util.commit("setError", error);
    } finally {
      this.util.commit("setLoading", false);
    }
  }

  async signOut() {
    try {
      this.util.commit("setLoading", true);

      await Vue.axios.post(
        this.state.base.url,
        {
          cmd: "logout"
        },
        {
          headers: {
            Authorization: `{"token":["${this.state.user.token}","${this.state.user.tokenid}"]}`
          }
        }
      );
    } catch (error) {
      this.util.commit("setError", error);
    } finally {
      this.util.commit("setLoading", false);
    }
  }

  async getServers() {
    try {
      this.util.commit("setLoading", true);

      const response = await Vue.axios.post(this.state.auth.url, {
        cmd: "getServers",
        type: "byt"
      });

      const result: CheckResult = checkResponse(response);
      if (result.status === StatusType.ok) {
        this.mutations.setAuthServers(response.data);
      } else {
        this.util.commit("setError", result);
      }
    } catch (error) {
      this.util.commit("setError", error);
    } finally {
      this.util.commit("setLoading", false);
    }
  }

  async getSections({ serverid }: GetSections) {
    try {
      this.util.commit("setLoading", true);

      const response = await Vue.axios.post(this.state.auth.url, {
        cmd: "getSections",
        type: "byt",
        serverid: `${serverid}`
      });

      const result: CheckResult = checkResponse(response);
      if (result.status === StatusType.ok) {
        this.mutations.setAuthSections(response.data);
      } else {
        this.util.commit("setError", result);
      }
    } catch (error) {
      this.util.commit("setError", error);
    } finally {
      this.util.commit("setLoading", false);
    }
  }
}

export const AuthStore = new Module({
  state: AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions
});
