import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import i18n from "./i18n";
import Element from "element-ui";
// import {
//   Card,
//   Form,
//   FormItem,
//   Input,
//   Select,
//   Option,
//   DatePicker,
//   Button,
//   Loading
// } from "element-ui";
import axios from "axios";
import VueAxios from "vue-axios";
import StoragePlugin from "vue-web-storage";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faLock,
  faGlobe,
  faLink,
  faServer,
  faClone,
  faDatabase
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUser, faLock, faGlobe, faLink, faServer, faClone, faDatabase);
Vue.component("font-awesome-icon", FontAwesomeIcon);
dom.watch();

import "element-ui/lib/theme-chalk/reset.css";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;

Vue.use(Element, {
  size: "medium",
  i18n: (key: string, value: string) => i18n.t(key, value)
});

// Vue.prototype.$ELEMENT = {
//   size: "medium",
//   i18n: (key: string, value: string) => i18n.t(key, value)
// };
// Vue.use(Card);
// Vue.use(Form);
// Vue.use(FormItem);
// Vue.use(Input);
// Vue.use(Select);
// Vue.use(Option);
// Vue.use(DatePicker);
// Vue.use(Button);
// Vue.use(Loading.directive);
// Vue.prototype.$loading = Loading.service;

Vue.use(VueAxios, axios);

Vue.use(StoragePlugin, {
  prefix: "wb-"
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
