import Vue from "vue";
import * as Vuex from "vuex";
import { Module, createStore } from "vuex-smart-module";
import { AuthStore } from "./modules/auth";
import { UtilStore } from "./modules/util";

Vue.use(Vuex);

export const root = new Module({
  modules: {
    AuthStore,
    UtilStore
  }
});

export const store = createStore(root);
