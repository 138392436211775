

















































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import { State, Mutation, Action, Getter } from "vuex-class";
import { webVersion } from "@/helpers/version";
import { AuthStore, UtilStore } from "@/helpers/state";
import { ElForm } from "element-ui/types/form";
import { Language, Connection, Config } from "@/types/config";
import { LSConnection } from "@/types/localStorage";
import {
  SignIn,
  GetSections,
  AuthServers,
  AuthSections,
  AuthBases
} from "@/types/auth";

@Component({
  name: "Signin"
})
export default class Signin extends Vue {
  @State("config", UtilStore) config!: Config;

  @Mutation("setAuthUrl", AuthStore) setAuthUrl!: (payload: string) => void;
  @Mutation("setAuthSections", AuthStore) setAuthSections!: (
    payload: Array<void>
  ) => void;
  @Mutation("setAuthBases", AuthStore) setAuthBases!: (
    payload: Array<AuthBases>
  ) => void;

  @Action("getConfig", UtilStore) getConfig!: () => Promise<void>;
  @Action("signIn", AuthStore) signIn!: (signIn: SignIn) => Promise<void>;
  @Action("getServers", AuthStore) getServers!: () => Promise<void>;
  @Action("getSections", AuthStore) getSections!: (
    sections: GetSections
  ) => Promise<void>;

  @Getter("authServers", AuthStore) authServers!: Array<AuthServers>;
  @Getter("authSections", AuthStore) authSections!: Array<AuthSections>;
  @Getter("authBases", AuthStore) authBases!: Array<AuthBases>;

  moduleVersion = webVersion();

  languages: Array<Language> = [];
  connections: Array<Connection> = [];
  serverDisabled = false;
  sectionDisabled = false;
  baseDisabled = false;
  storedServer = "";
  storedSection = "";
  storedBase = "";
  languageShow = true;
  connectionShow = true;
  authServer = false;
  userForm = {
    username: "",
    password: "",
    language: "",
    connection: "",
    server: "",
    section: "",
    base: ""
  };
  userRules = {
    username: [
      {
        required: true,
        message: this.$t("signin.userRules.username.required"),
        trigger: "blur"
      },
      {
        min: 3,
        message: this.$t("signin.userRules.username.min"),
        trigger: "blur"
      },
      {
        max: 24,
        message: this.$t("signin.userRules.username.max"),
        trigger: "blur"
      }
    ],
    password: [
      {
        required: true,
        message: this.$t("signin.userRules.password.required"),
        trigger: "blur"
      },
      {
        min: 3,
        message: this.$t("signin.userRules.password.min"),
        trigger: "blur"
      },
      {
        max: 24,
        message: this.$t("signin.userRules.password.max"),
        trigger: "blur"
      }
    ],
    language: [
      {
        required: true,
        message: this.$t("signin.userRules.language.required"),
        trigger: "blur"
      }
    ],
    connection: [
      {
        required: true,
        message: this.$t("signin.userRules.connection.required"),
        trigger: "blur"
      }
    ],
    server: [
      {
        required: true,
        message: this.$t("signin.userRules.server.required"),
        trigger: "blur"
      }
    ],
    section: [
      {
        required: true,
        message: this.$t("signin.userRules.section.required"),
        trigger: "blur"
      }
    ],
    base: [
      {
        required: true,
        message: this.$t("signin.userRules.base.required"),
        trigger: "blur"
      }
    ]
  };

  @Watch("authServers")
  onChangedAuthServers(servers: Array<AuthServers>) {
    if (servers) {
      if (this.storedServer) {
        const checkServer = servers.filter(
          item => item.id === this.storedServer
        );
        if (checkServer.length === 1) {
          this.userForm.server = checkServer[0].id;
          this.storedServer = "";
        }
      }
      if (servers.length === 1) {
        this.userForm.server = servers[0].id;
        this.serverDisabled = true;
      } else {
        this.serverDisabled = false;
      }
    } else {
      this.setAuthSections([]);
      this.setAuthBases([]);
      this.userForm.section = "";
      this.userForm.base = "";
    }
  }

  @Watch("authSections")
  onChangedAuthSections(sections: Array<AuthSections>) {
    if (sections) {
      if (this.storedSection) {
        const checkSection = sections.filter(
          item => item.section === this.storedSection
        );
        if (checkSection.length === 1) {
          this.userForm.section = checkSection[0].section;
          this.storedSection = "";
        }
      }
      if (sections.length === 1) {
        this.userForm.section = sections[0].section;
        this.sectionDisabled = true;
      } else {
        this.sectionDisabled = false;
      }
    } else {
      this.setAuthBases([]);
      this.userForm.base = "";
    }
  }

  @Watch("authBases")
  onChangedAuthBases(bases: Array<AuthBases>) {
    if (bases) {
      if (this.storedBase) {
        const checkBase = bases.filter(item => item.base === this.storedBase);
        if (checkBase.length === 1) {
          this.userForm.base = checkBase[0].base;
          this.storedBase = "";
        }
      }
      if (bases.length === 1) {
        this.userForm.base = bases[0].base;
        this.baseDisabled = true;
      } else {
        this.baseDisabled = false;
      }
    }
  }

  @Watch("userForm.server")
  async onChangedUserFormServer(serverid: string) {
    if (serverid) {
      await this.getSections({ serverid });
    } else {
      this.setAuthSections([]);
      this.userForm.section = "";
      this.userForm.base = "";
    }
  }

  @Watch("userForm.section")
  async onChangedUserFormSection(section: string) {
    this.userForm.base = "";
    const sections: Array<AuthSections> = this.authSections.filter(
      item => item.section === section
    );
    const bases: Array<AuthBases> =
      sections.length > 0 ? sections[0].bases : [];
    this.setAuthBases(bases);
  }

  async created() {
    document.title = this.$t("signin.title") as string;

    await this.getConfig();

    this.languages = [...this.config.languages];
    this.connections = [...this.config.connections];

    let fillLanguage = "";
    if (this.languages.length === 1) {
      fillLanguage = this.languages[0].value;
      this.languageShow = false;
    }

    let fillConnection = "";
    if (this.connections.length === 1) {
      fillConnection = this.connections[0].value;
      this.connectionShow = false;
    }

    const hasOwnProperty = Object.prototype.hasOwnProperty;

    if (Vue.$localStorage.hasKey("connection")) {
      const wbConnection: LSConnection = Vue.$localStorage.get("connection");
      this.userForm.username = hasOwnProperty.call(wbConnection, "login")
        ? wbConnection.login
        : "";
      const language = hasOwnProperty.call(wbConnection, "language")
        ? wbConnection.language
        : fillLanguage;
      this.userForm.language = language === "ua" ? "uk" : language;
      this.userForm.connection = hasOwnProperty.call(wbConnection, "connection")
        ? wbConnection.connection
        : fillConnection;
      this.storedServer = hasOwnProperty.call(wbConnection, "server")
        ? wbConnection.server
        : "";
      this.storedSection = hasOwnProperty.call(wbConnection, "section")
        ? wbConnection.section
        : "";
      this.storedBase = hasOwnProperty.call(wbConnection, "base")
        ? wbConnection.base
        : "";
    } else {
      this.userForm.username = "";
      this.userForm.language = fillLanguage;
      this.userForm.connection = fillConnection;
    }

    if (hasOwnProperty.call(this.config, "authserver")) {
      this.setAuthUrl(this.config.authserver);
      await this.getServers();
      this.authServer = true;
    } else {
      this.setAuthUrl("");
      this.authServer = false;
    }
  }

  onSignIn() {
    (this.$refs.userForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.$i18n.locale = this.userForm.language;

        let url: string, label: string, connection: string;
        if (this.authServer) {
          const selectBase: Array<AuthBases> = this.authBases.filter(
            item => item.base === this.userForm.base
          );
          url = selectBase[0].url;
          label = selectBase[0].base;
          connection = "";
        } else {
          const selectConnection: Array<Connection> = this.connections.filter(
            item => item.value === this.userForm.connection
          );
          url = selectConnection[0].url;
          label = selectConnection[0].label;
          connection = selectConnection[0].value;
        }

        this.signIn({
          login: this.userForm.username,
          password: this.userForm.password,
          language: this.userForm.language,
          connection,
          url,
          label,
          server: this.userForm.server,
          section: this.userForm.section,
          base: this.userForm.base
        });
      }
    });
  }
}
