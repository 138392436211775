import Vue from "vue";
import { root, store } from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import { CheckResult, StatusType } from "@/types/checkResult";
import { Response } from "@/types/response";

const ctx = root.context(store);
const authCtx = ctx.modules.AuthStore;
const utilCtx = ctx.modules.UtilStore;

function clearSession(): void {
  authCtx.mutations.setUser({
    login: null,
    language: null,
    token: null,
    tokenid: null,
    userid: null
  });
  authCtx.mutations.setBase({
    url: "",
    label: ""
  });
  authCtx.mutations.setAuth({
    url: "",
    servers: [],
    sections: [],
    bases: []
  });

  Vue.$localStorage.remove("auth");

  router.push("/signin");
}

function checkResponse(response: Response): CheckResult {
  if (response.headers.scgi_status === "0") {
    // eslint-disable-next-line
    //console.log("Result:", response.data);

    return {
      status: StatusType.ok
    };
  } else {
    // eslint-disable-next-line
    //console.error("Error:", response.data.error);

    const classError = response.data.error.class;
    const codeError = response.data.error.code;
    let msgError: string = response.data.error.msg;
    const msgOrigError = response.data.error.msg_orig || "";
    const msgStackError = response.data.error.stack || "";

    if (classError === "auth") {
      if (codeError === 100) {
        msgError = i18n.t("common.errors.usernameNotFoundInDatabase") as string;
      } else if (codeError === 101) {
        msgError = i18n.t("common.errors.usernameOrPasswordNotFound") as string;
      } else if (codeError === 102) {
        msgError = i18n.t("common.errors.accessDenied") as string;
      } else if (codeError === 103) {
        msgError = i18n.t("common.errors.invalidUsernameOrPassword") as string;
      } else if (codeError === 104) {
        msgError = i18n.t("common.errors.accountExpired") as string;
      } else if (
        codeError === 1 ||
        codeError === 2 ||
        codeError === 3 ||
        codeError === 10
      ) {
        msgError = i18n.t("common.errors.sessionExpired") as string;
        utilCtx.mutations.setError({
          status: StatusType.error,
          msgError,
          msgOrigError,
          msgStackError
        });
        clearSession();
      }
    }

    return {
      status: StatusType.error,
      msgError,
      msgOrigError,
      msgStackError
    };
  }
}

export { checkResponse, clearSession };
