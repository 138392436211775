






import { Component, Watch, Vue } from "vue-property-decorator";
import { State, Mutation } from "vuex-class";
import { VNode } from "vue";
// import { clearSession } from "@/helpers/store";
import { UtilStore } from "@/helpers/state";
import { CheckResult } from "@/types/checkResult";
import { LSConnection } from "@/types/localStorage";

@Component({
  name: "App"
})
export default class App extends Vue {
  @State("error", UtilStore) error!: CheckResult;
  @State("loading", UtilStore) loading!: boolean;

  @Mutation("clearError", UtilStore) clearError!: () => void;

  @Watch("error")
  onErrorChanged(messages: CheckResult) {
    if (messages) {
      //let axiosError = false;
      let result: string | VNode;
      const hasOwnProperty = Object.prototype.hasOwnProperty;
      if (hasOwnProperty.call(messages, "isAxiosError")) {
        result = this.$t("common.errors.networkError") as string;
        //axiosError = true;
      } else {
        if (messages.msgOrigError === "" && messages.msgStackError === "") {
          result = messages.msgError as string;
        } else {
          const h = this.$createElement;
          result = h("details", {}, [
            h("summary", { style: "margin-bottom: 10px" }, messages.msgError),
            h("span", { style: "color: grey" }, messages.msgOrigError),
            h("span", { style: "color: grey" }, messages.msgStackError)
          ]);
        }
      }

      this.$alert(result as string, this.$t("common.errors.title") as string, {
        confirmButtonText: "OK",
        type: "error",
        callback: () => {
          this.clearError();
          //if (axiosError) clearSession();
        }
      });
    }
  }

  created(): void {
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    if (Vue.$localStorage.hasKey("connection")) {
      const wbConnection: LSConnection = Vue.$localStorage.get("connection");
      const wbConnectionLanguage = hasOwnProperty.call(wbConnection, "language")
        ? wbConnection.language
        : "";
      const lsLocale =
        wbConnectionLanguage === "ua" ? "uk" : wbConnectionLanguage;
      if (this.$i18n.locale !== lsLocale) this.$i18n.locale = lsLocale;
    }
  }
}
