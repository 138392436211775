import { Navigator } from "@/types/navigator";

const getBrowserlocale = (): string => {
  const navigator: Navigator = window.navigator;

  const lang = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.browserLanguage || navigator.userLanguage;

  let shortLang = lang || "";
  if (shortLang.indexOf("-") !== -1) shortLang = shortLang.split("-")[0];
  if (shortLang.indexOf("_") !== -1) shortLang = shortLang.split("_")[0];

  return shortLang;
};

export { getBrowserlocale };
