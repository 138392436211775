import Vue from "vue";
import VueRouter, { Route, NavigationGuardNext } from "vue-router"; // RouteConfig,
import Home from "@/views/Home.vue";
import Signin from "@/views/Signin.vue";
import Signout from "@/views/Signout.vue";
import { root, store } from "@/store";
import { LSAuth, LSConnection, LLConnection } from "@/types/localStorage";

const ctx = root.context(store);
const authCtx = ctx.modules.AuthStore;

Vue.use(VueRouter);

function restoreSession(callFrom: string, next: NavigationGuardNext): void {
  const hasOwnProperty = Object.prototype.hasOwnProperty;

  const lsConnection: LLConnection = {
    login: "",
    language: ""
    // connection: "",
    // server: "",
    // section: "",
    // base: ""
  };

  if (Vue.$localStorage.hasKey("connection")) {
    const wbConnection: LSConnection = Vue.$localStorage.get("connection");
    lsConnection.login = hasOwnProperty.call(wbConnection, "login")
      ? wbConnection.login
      : "";
    lsConnection.language = hasOwnProperty.call(wbConnection, "language")
      ? wbConnection.language
      : "";
    // lsConnection.connection = hasOwnProperty.call(wbConnection, "connection")
    // ? wbConnection.connection
    // : "";
    // lsConnection.server = hasOwnProperty.call(wbConnection, "server")
    // ? wbConnection.server
    // : "";
    // lsConnection.section = hasOwnProperty.call(wbConnection, "section")
    // ? wbConnection.section
    // : "";
    // lsConnection.base = hasOwnProperty.call(wbConnection, "base")
    // ? wbConnection.base
    // : "";
  }

  const lsAuth: LSAuth = {
    url: "",
    label: "",
    token: "",
    tokenid: 0,
    userid: 0
  };

  if (Vue.$localStorage.hasKey("auth")) {
    const wbAuth: LSAuth = Vue.$localStorage.get("auth");
    lsAuth.url = hasOwnProperty.call(wbAuth, "url") ? wbAuth.url : "";
    lsAuth.label = hasOwnProperty.call(wbAuth, "label") ? wbAuth.label : "";
    lsAuth.token = hasOwnProperty.call(wbAuth, "token") ? wbAuth.token : "";
    lsAuth.tokenid = hasOwnProperty.call(wbAuth, "tokenid")
      ? wbAuth.tokenid
      : 0;
    lsAuth.userid = hasOwnProperty.call(wbAuth, "url") ? wbAuth.userid : 0;
  }

  const checkConnection =
    lsConnection.login !== "" && lsConnection.language !== "";
  //   lsConnection.connection !== "" &&
  //   lsConnection.server !== "" &&
  //   lsConnection.section !== "" &&
  //   lsConnection.base !== "";

  const checkAuth =
    lsAuth.url !== "" &&
    lsAuth.label !== "" &&
    lsAuth.token !== "" &&
    lsAuth.tokenid !== 0 &&
    lsAuth.userid !== 0;

  if (checkConnection && checkAuth) {
    authCtx.mutations.setUser({
      login: lsConnection.login,
      language: lsConnection.language,
      token: lsAuth.token,
      tokenid: String(lsAuth.tokenid),
      userid: String(lsAuth.userid)
    });
    authCtx.mutations.setBase({
      url: lsAuth.url,
      label: lsAuth.label
    });

    callFrom === "internal" ? next() : next("/");
  } else {
    callFrom === "internal" ? next("/signin") : next();
  }
}

function guardExternal(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  store.getters.isUserSignin ? next("/") : restoreSession("external", next);
}

function guardInternal(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  store.getters.isUserSignin ? next() : restoreSession("internal", next);
}

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/signin",
      name: "Signin",
      component: Signin,
      beforeEnter: guardExternal
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      beforeEnter: guardInternal
    },
    {
      path: "/signout",
      name: "Signout",
      component: Signout,
      beforeEnter: guardInternal
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});
