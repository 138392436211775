


















































import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import { getHost } from "@/helpers/url";
import { webVersion } from "@/helpers/version";
import { AuthStore, UtilStore } from "@/helpers/state";
import { ApiVersion, User, Base } from "@/types/state";
import GlobalMenu from "@/components/GlobalMenu.vue";

@Component({
  name: "Home",
  components: { GlobalMenu }
})
export default class Home extends Vue {
  @State("apiVersion", UtilStore) apiVersion!: ApiVersion;
  @State("user", AuthStore) user!: User;
  @State("base", AuthStore) base!: Base;

  @Action("ping", UtilStore) ping!: () => Promise<void>;
  @Action("getApiVersion", UtilStore) getApiVersion!: () => Promise<void>;

  currentHost = getHost();
  moduleVersion: string = webVersion();

  async created() {
    document.title = this.$t("home.title") as string;

    await this.ping();
    await this.getApiVersion();
  }

  onMoveTo(module: string): void {
    window.location.href = `${this.currentHost}${module}/`;
  }

  onSignOut(): void {
    this.$router.push("/signout");
  }
}
