export enum StatusType {
  ok,
  error
}

export type CheckResult = {
  status: StatusType;
  msgError?: string;
  msgOrigError?: string;
  msgStackError?: string;
  isAxiosError?: string;
};
